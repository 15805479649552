import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormField from './sre-form-field';
import { toast } from 'react-toastify';
import { createService } from '../../../api/settings-slo/slo';
import { replaceSpacesToUnderscores } from '../../../helpers/replaceSpacesToUnderscores';
import { useTranslation } from 'react-i18next';
import { URL_VALIDATION } from '../../../helpers/constants';

const AddingServiceForm = ({
    handleChangeModalState,
    services,
    servicesDispatch,
}) => {
    let initValue = {
        service: '',
        description: '',
        url: '',
    };

    const { t } = useTranslation();

    const successNotify = (message) => toast.success(message);
    const errorNotify = (message) => toast.error(message);

    const validationSchema = Yup.object({
        service: Yup.string().required('Service name is required'),
        description: Yup.string(),
        url: Yup.string().matches(URL_VALIDATION, 'Enter a valid url'),
    });

    return (
        <Formik
            initialValues={initValue}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm, setSubmitting }) => {
                const transformedServiceName = replaceSpacesToUnderscores(
                    values.service,
                    true
                );
                console.log(values);
                const data = {
                    service: transformedServiceName,
                    description: values.description,
                    url: values.url,
                };

                createService(data)
                    .then((response) => {
                        if (response.status === 200) {
                            servicesDispatch({
                                type: 'SET_DATA',
                                payload: [...services.data, data],
                            });
                            resetForm({});
                            setSubmitting(false);
                            successNotify(
                                `Service "${values.service}" has been added`
                            );
                            handleChangeModalState();
                        }
                    })
                    .catch((error) => {
                        servicesDispatch({ type: 'SET_ERROR', payload: error });
                        errorNotify(error?.response?.data);
                    });
            }}
        >
            {({ errors, touched, values }) => (
                <Form className="flex flex-col h-full">
                    <fieldset className="flex flex-col">
                        <div className="flex">
                            <div className="mb-2 mr-3 w-1/3">
                                <FormField
                                    name={t('sre.service_name')}
                                    attributeName={'service'}
                                    fieldType={'text'}
                                    placeholderText={t(
                                        'sre.service_name_placeholder'
                                    )}
                                    error={errors.service}
                                    touched={touched.service}
                                    value={values.service || ''}
                                />
                            </div>
                            <div className="mb-2 flex-grow">
                                <FormField
                                    name={t('sre.service_description')}
                                    attributeName={'description'}
                                    fieldType={'text'}
                                    placeholderText={t(
                                        'sre.service_description_placeholder'
                                    )}
                                    error={errors.description}
                                    touched={touched.description}
                                    value={values.description || ''}
                                />
                            </div>
                        </div>
                        <div className="">
                            <FormField
                                name={'URL'}
                                attributeName={'url'}
                                fieldType={'text'}
                                placeholderText={t(
                                    'sre.service_description_placeholder'
                                )}
                                error={errors.url}
                                touched={touched.url}
                                value={values.url || ''}
                            />
                        </div>
                    </fieldset>
                    <div className="flex mt-auto items-center">
                        <button
                            type="button"
                            className="ml-auto inline-flex mr-4 justify-center  px-6 py-2 text-lg leading-6 font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                            onClick={handleChangeModalState}
                            style={{ minWidth: '110px' }}
                        >
                            {t('button.cancel')}
                        </button>
                        <button
                            className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-button disabled:cursor-not-allowed disabled:opacity-50`}
                            style={{ minWidth: '110px' }}
                            disabled={!values.service}
                            type="submit"
                        >
                            {t('button.add')}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AddingServiceForm;

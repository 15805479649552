import React, { useContext, useState, useEffect } from 'react';
import { withUserAndOragnisations } from '../helpers/withUser';
import { UserContext } from '../context/UserContext';
import userAvatar from '../assets/user-avatar.png';
import ChangeLang from '../components/lang-chang';
import filterFile from '../helpers/fileValidation';
import { CrossInCircle } from '../assets/svg.js';
import InputGroup from '../components/custom/input';
import {
    resetUserPassword,
    setNewUserPhoto,
    updateUserData,
} from '../api/settings/UserAPI';
import { checkLoginOption } from '../api/auth/AuthAPI';
import showNotification from '../helpers/showNotification';
import { useTranslation } from 'react-i18next';
import { addUserPhotoToTheUserData } from '../helpers/fetchUserPhotos.js';
import { OrgUserListContext } from '../context/OrgUserListContext.js';

const UserSettingsPage = () => {
    const { t } = useTranslation();
    const { userState, userDispatch } = useContext(UserContext);
    const { orgUserListState, orgUserListDispatch } =
        useContext(OrgUserListContext);

    const [localUserState, setLocalUserState] = useState({
        user_name: '',
        photo: '',
        lang: 'en',
    });

    const [loginOption, setLoginOption] = useState(null);
    const [isResetEmailSent, setIsResetEmailSent] = useState(false);

    const [userPhoto, setUserPhoto] = useState({
        photoUrl: '',
        newPhoto: null,
        error: false,
        haveChange: false,
        loading: false,
    });

    useEffect(() => {
        let canceled = false;
        if (!canceled) {
            setLocalUserState(userState.data);
            setUserPhoto((prevState) => {
                return {
                    ...prevState,
                    photoUrl: userState.data.photo,
                };
            });
            void fetchLoginOption(userState.data.email);
        }

        return () => (canceled = true);
    }, [userState]);

    async function fetchLoginOption(email) {
        try {
            const res = await checkLoginOption({ email: email });
            res?.data && setLoginOption(res.data.login_option);
        } catch (error) {
            console.log(error);
        }
    }

    async function handleResetPassword() {
        try {
            resetUserPassword();
            setIsResetEmailSent(true);
        } catch (error) {
            showNotification(`${err.response?.data || err.message}`);
        }
    }

    const handleFileDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await setLocalUserPhoto(event.dataTransfer.files[0]);
    };

    const handleFileDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const setLocalUserPhoto = async (file) => {
        const photo = await filterFile(file);
        if (typeof photo === 'string') {
            setUserPhoto({
                photoUrl: userState.data.photo,
                newPhoto: null,
                error: photo,
                haveChange: false,
                loading: false,
            });
        } else {
            setUserPhoto((prevState) => {
                return {
                    ...prevState,
                    newPhoto: photo,
                    loading: false,
                };
            });
        }
    };

    const removePhoto = () => {
        if (userPhoto.newPhoto) {
            if (userPhoto.photoUrl === '') {
                setUserPhoto({
                    photoUrl: '',
                    newPhoto: null,
                    error: false,
                    haveChange: false,
                    loading: false,
                });
            } else {
                setUserPhoto({
                    photoUrl: '',
                    newPhoto: null,
                    error: false,
                    haveChange: true,
                    loading: false,
                });
            }
        } else {
            setUserPhoto({
                photoUrl: '',
                newPhoto: null,
                error: false,
                haveChange: true,
                loading: false,
            });
        }
    };

    const renderPhoto = () => {
        let logoBlock;

        if (userPhoto.newPhoto) {
            logoBlock = (
                <div className="user-avatar">
                    <img
                        src={window.URL.createObjectURL(userPhoto.newPhoto)}
                        alt="User Avatar"
                    />
                    <button className="remove-logo" onClick={removePhoto}>
                        <CrossInCircle fill="#EE8282" />
                    </button>
                </div>
            );
        } else if (
            userPhoto?.photoUrl &&
            userPhoto.photoUrl !== '' &&
            userPhoto.photoUrl !== 'data:image/jpeg;base64,' &&
            !userPhoto.error
        ) {
            logoBlock = (
                <div className="user-avatar">
                    <img src={userPhoto.photoUrl} alt="Default" />
                    <button className="remove-logo" onClick={removePhoto}>
                        <CrossInCircle fill="#EE8282" />
                    </button>
                </div>
            );
        } else {
            logoBlock = (
                <div className="user-avatar">
                    <div
                        className="drag-and-drop"
                        onDrop={handleFileDrop}
                        onDragOver={handleFileDragOver}
                    >
                        <img src={userAvatar} alt="Avatar" draggable="false" />
                        <span className="dnd-placeholder">
                            {t('user_settings_page.drag_and_drop')}
                        </span>
                        {userPhoto.error && (
                            <span className="text-red-text text-xs">
                                {userPhoto.error}
                            </span>
                        )}
                    </div>
                    <label className="upload-input">
                        <input
                            type="file"
                            onChange={(event) =>
                                setLocalUserPhoto(event.target.files[0])
                            }
                        />
                        {t('user_settings_page.select_image')}
                    </label>
                </div>
            );
        }

        return logoBlock;
    };

    const setLocalLang = (newLang) => {
        setLocalUserState((prevState) => {
            return {
                ...prevState,
                lang: newLang,
            };
        });
    };

    const updateUserTextData = async (data) => {
        try {
            const res = await updateUserData(data);
            if (res?.status === 200) {
                const updatedUser = { ...userState?.data, ...data };
                showNotification('Profile was successfully updated', 'success');
                userDispatch({
                    type: 'SET_DATA',
                    payload: updatedUser,
                });

                const updatedUsersList = orgUserListState?.data?.map((user) => {
                    if (user?.hash === updatedUser?.user_hash) {
                        return { ...user, ...data };
                    }
                    return user;
                });

                orgUserListDispatch({
                    type: 'SET_DATA',
                    payload: updatedUsersList,
                });
            }
        } catch (error) {
            showNotification(error.response?.data || error.message);
            userDispatch({
                type: 'SET_ERROR',
                payload: error.response?.data || error.message,
            });
        }
    };

    const updateUserPhoto = async (newPhoto) => {
        let fileData = new FormData();
        fileData.append('photo', newPhoto);
        setUserPhoto((prevState) => ({
            ...prevState,
            loading: true,
        }));
        try {
            const res = await setNewUserPhoto(fileData);
            let updatedUserData = {
                ...userState?.data,
                photo:
                    !res?.data || res?.data === 'Photo was removed'
                        ? null
                        : res?.data,
            };

            if (res?.data && res?.data !== 'Photo was removed') {
                try {
                    const dataWithPhoto = await addUserPhotoToTheUserData(
                        updatedUserData
                    );
                    updatedUserData = dataWithPhoto;
                } catch (error) {
                    showNotification('Photo parsing went wrong');
                }
            }

            showNotification('Photo has been updated', 'success');

            userDispatch({
                type: 'SET_DATA',
                payload: updatedUserData,
            });

            const updatedUsersList = orgUserListState?.data?.map((user) => {
                if (user?.hash === updatedUserData?.user_hash) {
                    return { ...user, photo: updatedUserData?.photo };
                }
                return user;
            });

            orgUserListDispatch({
                type: 'SET_DATA',
                payload: updatedUsersList,
            });
        } catch (error) {
            userDispatch({
                type: 'SET_ERROR',
                payload:
                    error.response?.data ||
                    error.data?.message ||
                    error.data ||
                    'Oops! Something went wrong',
            });
            showNotification(
                error.response?.data ||
                    error.data?.message ||
                    error.data ||
                    'Oops! Something went wrong'
            );
        }
    };

    const updateUserInfo = async () => {
        userDispatch({
            type: 'SET_LOADING',
        });
        const data = {
            user_name: localUserState.user_name,
            lang: localUserState.lang,
        };
        const hasUserNameChange =
            userState.data.user_name !== localUserState.user_name;
        const hasLangChange = userState.data.lang !== localUserState.lang;
        const hasPhotoChange =
            userState.data.photo !== userPhoto.photoUrl || userPhoto.newPhoto;

        if (hasUserNameChange || hasLangChange) {
            updateUserTextData(data);
        }

        if (hasPhotoChange) {
            updateUserPhoto(userPhoto.newPhoto);
        }
    };

    const areDisabled = () => {
        const hasUserNameChange =
            userState.data.user_name !== localUserState.user_name;
        const hasLangChange = userState.data.lang !== localUserState.lang;
        const hasPhotoChange =
            userState.data.photo !== userPhoto.photoUrl || userPhoto.newPhoto;

        return !(
            hasUserNameChange ||
            hasLangChange ||
            hasPhotoChange
        );
    };

    const setInputValue = (e) => {
        const { name, value } = e.target;

        // validate first & last names length
        if (
            (e.target.name === 'user_name') &&
            e.target.value.length > 54
        ) {
            return;
        }

        const stateClone = { ...localUserState };
        stateClone[name] = value;
        setLocalUserState(stateClone);
    };

    return (
        <div className="user-settings-page container mx-auto   grid grid-cols-12 gap-x-8 ">
            <div className="col-span-12">
                <h1 className="h1 text-center">
                    {t('user_settings_page.title')}
                </h1>
            </div>
            <div className="col-span-3">{renderPhoto()}</div>
            <div className="col-span-9">
                <div className="col-12">
                    <InputGroup
                        label={{
                            text: t('user_info.user_name'),
                            for: 'user_name',
                        }}
                        input={{
                            value: localUserState.user_name,
                            type: 'text',
                            name: 'user_name',
                            id: 'user_name',
                            placeholder: t('user_info.user_name'),
                            onChange: setInputValue,
                            style: {
                                marginTop: '10px',
                            },
                        }}
                    />

                    <InputGroup
                        label={{ text: t('user_info.email'), for: 'userEmail' }}
                        input={{
                            value:
                                userState.data.email !== ''
                                    ? userState.data.email
                                    : '',
                            type: 'email',
                            name: 'userEmail',
                            id: 'userEmail',
                            placeholder: t('user_info.email'),
                            onChange: setInputValue,
                            readOnly: true,
                            style: {
                                marginTop: '10px',
                            },
                            disabled: true,
                        }}
                    />

                    <div className="form-group mb-4">
                        <p className="sub-title">
                            {t('user_settings_page.language')}
                        </p>
                        <ChangeLang
                            color="#484a53"
                            callbackFunc={setLocalLang}
                        />
                    </div>
                    {loginOption === 'password' && (
                        <div className="form-group">
                            <p className="sub-title">Password</p>
                            {isResetEmailSent ? (
                                <p className="text-gray-500">
                                    Reset password link has been sent on your
                                    email
                                </p>
                            ) : (
                                <button
                                    className="p-2 border border-solid rounded border-success-green-300 text-success-green-300 disabled:border-gray-disabled disabled:text-gray-disabled"
                                    onClick={() => handleResetPassword()}
                                >
                                    Reset password
                                </button>
                            )}
                        </div>
                    )}
                    <button
                        disabled={areDisabled()}
                        onClick={updateUserInfo}
                        className="save-button"
                    >
                        {t('button.save')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default withUserAndOragnisations(UserSettingsPage);

import { getOrgUsers } from '../api/settings/UserAPI';
import { getUserPic } from '../api/user/UserAPI';

export async function addUserPhotoToTheUserData(userData) {
    let dataWithPhoto = userData;

    if (!userData?.photo || userData?.photo?.includes('None')) {
        dataWithPhoto = {
            ...userData,
            photo: null,
        };
    } else if (!userData?.photo?.startsWith('https://')) {
        try {
            const res = await getUserPic(userData?.photo);
            if (res?.data) {
                dataWithPhoto = {
                    ...userData,
                    photo: URL.createObjectURL(res.data),
                };
            }
        } catch (err) {
            dataWithPhoto = {
                ...userData,
                photo: null,
            };
        }
    }
    return dataWithPhoto;
}

export async function fetchUsersAndInsertPhotos(fetchPics = true) {
    let response = await getOrgUsers();

    if (response?.data?.length && fetchPics) {
        const insertedPhotoPromises = response?.data?.map(async (user) => {
            const updated = await addUserPhotoToTheUserData(user);
            return updated;
        });
        const withInsertedPhoto = await Promise.all(insertedPhotoPromises);
        response = { ...response, data: withInsertedPhoto };
    }

    return response;
}

export async function addUserPhotoToTheTicketData(ticketData) {
    let dataWithPhoto = ticketData;

    if (
        !ticketData?.assignee_photo ||
        ticketData?.assignee_photo?.includes('None')
    ) {
        dataWithPhoto = {
            ...ticketData,
            assignee_photo: null,
        };
    } else if (!ticketData?.assignee_photo?.startsWith('https://')) {
        try {
            const res = await getUserPic(ticketData?.assignee_photo);
            if (res?.data) {
                dataWithPhoto = {
                    ...ticketData,
                    assignee_photo: URL.createObjectURL(res.data),
                };
            }
        } catch (err) {
            dataWithPhoto = {
                ...ticketData,
                assignee_photo: null,
            };
        }
    }
    return dataWithPhoto;
}

export async function fetchUsersList(orgUserListDispatch) {
    orgUserListDispatch({ type: 'SET_LOADING' });
    try {
        const res = await fetchUsersAndInsertPhotos(false);
        if (res?.status === 200) {
            orgUserListDispatch({ type: 'SET_DATA', payload: res?.data });
            if (res?.data?.length && res?.data.find((user) => user?.photo)) {
                const insertedPhotoPromises = res?.data?.map(async (user) => {
                    const updated = await addUserPhotoToTheUserData(user);
                    return updated;
                });
                const withInsertedPhoto = await Promise.all(
                    insertedPhotoPromises
                );
                orgUserListDispatch({
                    type: 'SET_DATA_WITH_USERPICS',
                    payload: withInsertedPhoto,
                });
            } else {
                orgUserListDispatch({
                    type: 'SET_NO_LOADING_USERPICS',
                });
            }
        }
    } catch (error) {
        orgUserListDispatch({ type: 'SET_ERROR', payload: error });
    }
}

import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { OrganisationsContext } from '../../context/OrganisationsContext';

// tabsArray interface = {id: string, displayedName: string}[]

const TabsNav = ({ active, setActiveTab, tabNavsArray }) => {
    const { organisationsState } = useContext(OrganisationsContext);
    const { data, error } = organisationsState;
    const { pathname } = useLocation();

    const setTab = (tab) => {
        if (tab !== active && !error && data && data.length > 0)
            setActiveTab(tab);
    };

    return (
        <ul className="flex settings-navs text-gray-500 gap-x-2 mb-4 ">
            {tabNavsArray.map((tab) => (
                <li
                    className={`${
                        active === tab.id
                            ? ' border-b border-solid border-gray-500'
                            : 'border-b border-solid border-transparent'
                    }   hover:border-gray-500 transition ease-out`}
                    onClick={() => setTab(tab.id)}
                    key={tab.id}
                >
                    <NavLink
                        to={pathname.includes(tab.id) ? pathname : `${tab.id}`}
                        className="block px-2 py-1 no-underline font-display leading-6 cursor-pointer hover:text-gray-500"
                    >
                        {tab.displayedName}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

export default TabsNav;

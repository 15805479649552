import React, { useCallback, useContext, useState } from 'react';
import { OrganisationsContext } from '../context/OrganisationsContext';
import { getTeams } from '../api/teams/TeamsAPI';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import { getAdminPermission } from '../helpers/getPermission';
import Loader from '../components/loader';
import TeamEditor from '../components/team-editor.js';
import TeamCard from '../components/team-card/index.js';
import { OrgUserListContext } from '../context/OrgUserListContext.js';

function TeamsPage() {
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const teamsRequest = useCallback(() => getTeams(), []);
    const [teamsState, teamsDispatch] = useFetchDataAndSetState(teamsRequest, [
        selectedOrganisationHash,
    ]);
    const { orgUserListState } = useContext(OrgUserListContext);
    const [isCreateNewTeam, setIsCreateNewTeam] = useState(false);
    const [teamInEditingMode, setTeamInEditingMode] = useState(null);

    const permissionForWrite = getAdminPermission(organisationsState?.data);

    if (
        teamsState.loading ||
        orgUserListState.loading ||
        orgUserListState.loadingUserpics
    ) {
        return (
            <div className="settings-tab-content teams-tab flex justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div>
            {permissionForWrite && !isCreateNewTeam ? (
                <div className="flex gap-x-4 justify-end ">
                    <button
                        className="btn-white-hover-blue"
                        type="button"
                        onClick={() => {
                            setIsCreateNewTeam(true);
                        }}
                    >
                        Create new team
                    </button>
                </div>
            ) : null}
            <div className="mt-8">
                {isCreateNewTeam ? (
                    <TeamEditor
                        teamsState={teamsState}
                        teamsDispatch={teamsDispatch}
                        selectedOrganisationHash={selectedOrganisationHash}
                        isCreateNewTeam={isCreateNewTeam}
                        setIsCreateNewTeam={setIsCreateNewTeam}
                        setTeamInEditingMode={setTeamInEditingMode}
                    />
                ) : null}
                {teamsState?.data?.length ? (
                    <ul className="flex flex-col-reverse gap-4 mt-8">
                        {teamsState?.data.map((team, i) => (
                            <li key={team.team_hash}>
                                {teamInEditingMode === team.team_name ? (
                                    <TeamEditor
                                        teamsState={teamsState}
                                        teamsDispatch={teamsDispatch}
                                        selectedOrganisationHash={
                                            selectedOrganisationHash
                                        }
                                        isCreateNewTeam={isCreateNewTeam}
                                        setIsCreateNewTeam={setIsCreateNewTeam}
                                        teamData={team}
                                        setTeamInEditingMode={
                                            setTeamInEditingMode
                                        }
                                    />
                                ) : (
                                    <TeamCard
                                        teamData={team}
                                        teamsState={teamsState}
                                        usersState={orgUserListState}
                                        setTeamInEditingMode={
                                            setTeamInEditingMode
                                        }
                                        isFirst={
                                            i === teamsState?.data?.length - 1
                                        }
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-500 text-base text-center text-xl">
                        No teams
                    </p>
                )}
            </div>
        </div>
    );
}

export default TeamsPage;

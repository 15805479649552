import React, { useState } from 'react';
import { capitalizeFirstLetter } from '../../../../helpers/textFormatters';
import WhitePanelContentWrapper from '../../../white-panel-content-wrapper';
import { useTranslation } from 'react-i18next';
import { getBillingDetails } from '../../../../api/subscription/SubscriptionAPI';
import { useFetchDataAndSetState } from '../../../../helpers/useFetchDataAndSetState';
import { useCallback } from 'react';
import moment from 'moment';
import Loader from '../../../loader';

const PaymentMethod = ({ selectedOrganisationHash }) => {
    const { t } = useTranslation();
    const request = useCallback(() => getBillingDetails(), []);

    const [billingDetailsState] = useFetchDataAndSetState(request, [
        selectedOrganisationHash,
    ]);

    function getColor(status) {
        let color = 'bg-gray-500';
        switch (status) {
            case 'valid':
            case 'paid':
            case 'active':
                color = 'bg-green-500';
                break;
            case 'pending':
            case 'open':
            case 'authorized':
                color = 'bg-yellow-500';
                break;
            case 'failed':
            case 'canceled':
            case 'invalid':
            case 'expired':
                color = 'bg-red-500';
                break;
            default:
                break;
        }

        return color;
    }

    return (
        <>
            <WhitePanelContentWrapper className="w-8/12">
                <p className="font-display text-3xl">
                    {capitalizeFirstLetter(
                        t('settings_page.billing_tab.pay_method')
                    )}
                </p>

                <div className="w-8/12 my-5 grid grid-cols-4 gap-y-4 gap-x-4">
                    <div className="">
                        {capitalizeFirstLetter(
                            t('settings_page.billing_tab.method')
                        )}
                    </div>
                    <div className="">
                        {capitalizeFirstLetter('payment interval')}
                    </div>
                    <div className="">
                        {capitalizeFirstLetter('next payment')}
                    </div>
                    <div className="text-center">
                        {capitalizeFirstLetter(t('sre.status'))}
                    </div>
                    {billingDetailsState.loading ? (
                        <div className="col-span-4 flex w-full h-full justify-center items-center">
                            <Loader />
                        </div>
                    ) : billingDetailsState.data ? (
                        <>
                            <div className="">
                                {
                                    billingDetailsState?.data?.payment_method
                                        ?.method
                                }
                            </div>{' '}
                            <div>
                                {billingDetailsState?.data?.subscription
                                    ?.interval === '1 month'
                                    ? 'Monthly'
                                    : 'Annually'}
                            </div>
                            <div>
                                {moment(
                                    billingDetailsState?.data?.subscription
                                        ?.next_payment_date * 1000
                                ).format('YYYY-MM-DD')}
                            </div>
                            <div className="flex justify-center">
                                <span
                                    className={`${getColor(
                                        billingDetailsState?.data?.subscription
                                            ?.status
                                    )} rounded-md text-white mіn-w-20 h-6 flex justify-center items-center px-2`}
                                >
                                    {capitalizeFirstLetter(
                                        billingDetailsState?.data?.subscription
                                            ?.status
                                    )}
                                </span>
                            </div>
                        </>
                    ) : (
                        <div className="col-span-4 text-gray-500 mt-5">
                            {' '}
                            No payment details
                        </div>
                    )}
                </div>
            </WhitePanelContentWrapper>
        </>
    );
};

export default PaymentMethod;

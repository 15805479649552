import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import logoAgileAnalytics from '../../assets/logo-white-new.png';
import {
    OverviewIcon,
    RepositoriesIcon,
    StockIcon,
    LeaksIcon,
    KudosIcon,
    HoursIcon,
    SREIcon,
    SprintInsightsIcon,
    SettingsIcon,
    CreateOrganisation,
    SuperadminIcon,
    TeamsIcon,
} from '../../assets/menu-svg';
import { NAVIGATION_PATHS } from '../../constants';
import { UserContext } from '../../context/UserContext';
import ModalWrapper from '../modal-wrapper';
import ModalSupportFormContent from '../modal-support-form-content';
import { getSuperAdminPermission } from '../../helpers/getPermission';

const SidebarNavigation = ({ isOpen }) => {
    const { t } = useTranslation();
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const { organisationsState } = useContext(OrganisationsContext);
    const { userState } = useContext(UserContext);

    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active)?.org_hash
        : null;

    const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

    const [links, setLinks] = useState([
        {
            href: NAVIGATION_PATHS.overview,
            text: 'overview',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.teams,
            text: 'teams',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.kudos,
            text: 'kudos',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.hours,
            text: 'hours',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.sprintInsights,
            text: 'sprint-insights',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.sre,
            text: 'error-budgets',
            hovered: false,
            isActive: false,
        },
        // {
        //     href: NAVIGATION_PATHS.repositories,
        //     text: 'repositories',
        //     hovered: false,
        //     isActive: false,
        // },
        {
            href: NAVIGATION_PATHS.dora,
            text: 'dora',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.stock,
            text: 'stock',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.leaks,
            text: 'leaks',
            hovered: false,
            isActive: false,
        },
        {
            href: `${NAVIGATION_PATHS.settings}`,
            text: 'settings',
            hovered: false,
            isActive: false,
        },
        {
            href: `${NAVIGATION_PATHS.admin}`,
            text: 'superadmin',
            hovered: false,
            isActive: false,
        },
    ]);

    const [linksDemo, setLinksDemo] = useState([
        {
            href: NAVIGATION_PATHS.overview,
            text: 'overview',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.teams,
            text: 'teams',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.kudos,
            text: 'kudos',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.hours,
            text: 'hours',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.sprintInsights,
            text: 'sprint-insights',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.sre,
            text: 'error-budgets',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.dora,
            text: 'dora',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.stock,
            text: 'stock',
            hovered: false,
            isActive: false,
        },
        {
            href: NAVIGATION_PATHS.leaks,
            text: 'leaks',
            hovered: false,
            isActive: false,
        },
        // {
        //     href: `${NAVIGATION_PATHS.settings}`,
        //     text: 'settings',
        //     hovered: false,
        //     isActive: false,
        // },
        // {
        //     href: `${NAVIGATION_PATHS.admin}`,
        //     text: 'superadmin',
        //     hovered: false,
        //     isActive: false,
        // },
        // {
        //     href: NAVIGATION_PATHS.repositories,
        //     text: 'repositories',
        //     hovered: false,
        //     isActive: false,
        //     subitems: [
        //         {
        //             href: NAVIGATION_PATHS.stock,
        //             text: 'stock',
        //             hovered: false,
        //             isActive: false,
        //         },
        //         {
        //             href: NAVIGATION_PATHS.leaks,
        //             text: 'leaks',
        //             hovered: false,
        //             isActive: false,
        //         },
        //     ],
        // },
        // {
        //     href: '/create-organisation',
        //     text: 'create-organisation',
        //     hovered: false,
        //     isActive: false,
        // },
    ]);

    useEffect(() => {
        const updatedLinks = links.map((link) => {
            if (
                window.location.pathname.includes(link.href) &&
                !window.location.pathname.includes('settings')
            ) {
                link.isActive = true;
                return link;
            }

            if (
                link.href === NAVIGATION_PATHS.settings &&
                window.location.pathname.includes(link.href)
            ) {
                link.isActive = true;
            } else {
                link.isActive = false;
            }
            return link;
        });
        setLinks(updatedLinks);
        const updatedDemoLinks = linksDemo.map((link) => {
            if (
                window.location.pathname.includes(link.href) &&
                !window.location.pathname.includes('settings')
            ) {
                link.isActive = true;
                return link;
            }

            if (
                link.href === NAVIGATION_PATHS.settings &&
                window.location.pathname.includes(link.href)
            ) {
                link.isActive = true;
            } else {
                link.isActive = false;
            }
            return link;
        });
        setLinksDemo(updatedDemoLinks);
    }, [window.location.pathname]);

    const handleLinkHover = (text) => {
        const updatedLinks = links.map((link) => {
            if (link.text === text) {
                link.hovered = !link.hovered;
            }
            if (link.subitems) {
                link.subitems.map((item) => {
                    if (item.text === text) {
                        item.hovered = !item.hovered;
                    }
                });
            }
            return link;
        });
        setLinks(updatedLinks);
        const updatedDemoLinks = linksDemo.map((link) => {
            if (link.text === text) {
                link.hovered = !link.hovered;
            }
            if (link.subitems) {
                link.subitems.map((item) => {
                    if (item.text === text) {
                        item.hovered = !item.hovered;
                    }
                });
            }
            return link;
        });
        setLinksDemo(updatedDemoLinks);
    };

    const handleLinkClick = (text) => {
        const updatedLinks = links.map((link) => {
            if (link.text === text) {
                link.isActive = true;
            } else {
                link.isActive = false;
            }
            if (link.subitems) {
                link.subitems.map((item) => {
                    if (item.text === text) {
                        item.isActive = true;
                    } else {
                        item.isActive = false;
                    }
                });
            }
            return link;
        });
        setLinks(updatedLinks);
        const updatedDemoLinks = linksDemo.map((link) => {
            if (link.text === text) {
                link.isActive = true;
            } else {
                link.isActive = false;
            }
            if (link.subitems) {
                link.subitems.map((item) => {
                    if (item.text === text) {
                        item.isActive = true;
                    } else {
                        item.isActive = false;
                    }
                });
            }
            return link;
        });
        setLinksDemo(updatedDemoLinks);
    };

    const removeTourAutoplay = () => {
        const isAutoplay = localStorage.getItem('autoplay');

        if (isAutoplay) {
            localStorage.removeItem('autoplay');
        }
    };

    return (
        <>
            <aside
                className={` ${
                    isOpen ? 'block' : 'hidden'
                } w-64 flex-none bg-gray-600 transform transition-all ease-in-out flex flex-col`}
            >
                <div className="bg-gray-700 p-2 pt-4">
                    <NavLink to="/" className="" data-testid="logo">
                        <img
                            src={logoAgileAnalytics}
                            alt="Agile Analytics logo"
                            className="w-36 m-auto"
                        />
                    </NavLink>
                </div>

                <div className="flex-grow flex flex-col justify-between px-6 pt-9 pb-2">
                    <nav className="" onClick={() => removeTourAutoplay()}>
                        {selectedOrgHash !== demoOrgHash &&
                            links.map(
                                ({
                                    href,
                                    text,
                                    hovered,
                                    isActive,
                                    subitems,
                                }) => {
                                    if (
                                        text === 'superadmin' &&
                                        !getSuperAdminPermission(
                                            organisationsState?.data
                                        )
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <div key={text}>
                                            <NavLink
                                                to={href}
                                                className={({ isActive }) =>
                                                    'block flex items-center mb-3 no-underline ' +
                                                    (isActive
                                                        ? 'text-theme-primary'
                                                        : 'text-gray-300 hover:text-theme-primary')
                                                }
                                                onMouseEnter={() =>
                                                    handleLinkHover(text)
                                                }
                                                onMouseLeave={() =>
                                                    handleLinkHover(text)
                                                }
                                                onClick={() =>
                                                    handleLinkClick(text)
                                                }
                                            >
                                                <div className="w-6 h-6 flex-none">
                                                    {text === 'overview' && (
                                                        <OverviewIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'kudos' && (
                                                        <KudosIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'hours' && (
                                                        <HoursIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text ===
                                                        'sprint-insights' && (
                                                        <SprintInsightsIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text ===
                                                        'error-budgets' && (
                                                        <SREIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'teams' && (
                                                        <TeamsIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'settings' && (
                                                        <SettingsIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {/* {text === 'repositories' && (
                                                    <RepositoriesIcon
                                                        hovered={hovered}
                                                        isActive={isActive}
                                                    />
                                                )} */}
                                                    {text === 'dora' && (
                                                        <RepositoriesIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'stock' && (
                                                        <StockIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'leaks' && (
                                                        <LeaksIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'superadmin' && (
                                                        <SuperadminIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                </div>
                                                <span className="ml-4 font-display capitalize font-medium leading-7.5">
                                                    {t(`pages.${text}`)}
                                                </span>
                                            </NavLink>
                                            {subitems &&
                                                subitems.map((item) => {
                                                    return (
                                                        <NavLink
                                                            to={item.href}
                                                            key={item.text}
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                'ml-8 block flex items-center mb-3 no-underline text-gray-300 hover:text-theme-primary' +
                                                                (isActive
                                                                    ? 'text-theme-primary'
                                                                    : '')
                                                            }
                                                            onMouseEnter={() =>
                                                                handleLinkHover(
                                                                    item.text
                                                                )
                                                            }
                                                            onMouseLeave={() =>
                                                                handleLinkHover(
                                                                    item.text
                                                                )
                                                            }
                                                            onClick={() =>
                                                                handleLinkClick(
                                                                    item.text
                                                                )
                                                            }
                                                        >
                                                            <div className="w-6 h-6 flex-none">
                                                                {item.text ===
                                                                    'stock' && (
                                                                    <StockIcon
                                                                        hovered={
                                                                            item.hovered
                                                                        }
                                                                        isActive={
                                                                            item.isActive
                                                                        }
                                                                    />
                                                                )}
                                                                {item.text ===
                                                                    'leaks' && (
                                                                    <LeaksIcon
                                                                        hovered={
                                                                            item.hovered
                                                                        }
                                                                        isActive={
                                                                            item.isActive
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                            <span className="ml-4 font-display capitalize font-medium leading-7.5">
                                                                {t(
                                                                    `pages.${item.text}`
                                                                )}
                                                            </span>
                                                        </NavLink>
                                                    );
                                                })}
                                        </div>
                                    );
                                }
                            )}

                        {selectedOrgHash === demoOrgHash &&
                            linksDemo.map(
                                ({
                                    href,
                                    text,
                                    hovered,
                                    isActive,
                                    subitems,
                                }) => {
                                    return (
                                        <div key={text}>
                                            <NavLink
                                                to={href}
                                                className={({ isActive }) =>
                                                    'block flex items-center mb-3 no-underline ' +
                                                    (isActive
                                                        ? 'text-theme-primary'
                                                        : 'text-gray-300 hover:text-theme-primary')
                                                }
                                                onMouseEnter={() =>
                                                    handleLinkHover(text)
                                                }
                                                onMouseLeave={() =>
                                                    handleLinkHover(text)
                                                }
                                                onClick={() =>
                                                    handleLinkClick(text)
                                                }
                                            >
                                                <div className="w-6 h-6 flex-none">
                                                    {text === 'overview' && (
                                                        <OverviewIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'kudos' && (
                                                        <KudosIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'hours' && (
                                                        <HoursIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text ===
                                                        'sprint-insights' && (
                                                        <SprintInsightsIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text ===
                                                        'error-budgets' && (
                                                        <SREIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'teams' && (
                                                        <TeamsIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'settings' && (
                                                        <SettingsIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {/* {text === 'repositories' && (
                                                    <RepositoriesIcon
                                                        hovered={hovered}
                                                        isActive={isActive}
                                                    />
                                                )} */}
                                                    {text === 'dora' && (
                                                        <RepositoriesIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'stock' && (
                                                        <StockIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'leaks' && (
                                                        <LeaksIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text ===
                                                        'create-organisation' && (
                                                        <CreateOrganisation
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                    {text === 'superadmin' && (
                                                        <SuperadminIcon
                                                            hovered={hovered}
                                                            isActive={isActive}
                                                        />
                                                    )}
                                                </div>
                                                <span className="ml-4 font-display capitalize font-medium leading-7.5">
                                                    {t(`pages.${text}`)}
                                                </span>
                                            </NavLink>
                                            {subitems &&
                                                subitems.map((item) => {
                                                    return (
                                                        <NavLink
                                                            to={item.href}
                                                            key={item.text}
                                                            className={({
                                                                isActive,
                                                            }) =>
                                                                'block flex items-center mb-3 no-underline ' +
                                                                (isActive
                                                                    ? 'text-theme-primary'
                                                                    : 'text-gray-300 hover:text-theme-primary')
                                                            }
                                                            onMouseEnter={() =>
                                                                handleLinkHover(
                                                                    item.text
                                                                )
                                                            }
                                                            onMouseLeave={() =>
                                                                handleLinkHover(
                                                                    item.text
                                                                )
                                                            }
                                                            onClick={() =>
                                                                handleLinkClick(
                                                                    item.text
                                                                )
                                                            }
                                                        >
                                                            <div className="w-6 h-6 flex-none">
                                                                {item.text ===
                                                                    'stock' && (
                                                                    <StockIcon
                                                                        hovered={
                                                                            item.hovered
                                                                        }
                                                                        isActive={
                                                                            item.isActive
                                                                        }
                                                                    />
                                                                )}
                                                                {item.text ===
                                                                    'leaks' && (
                                                                    <LeaksIcon
                                                                        hovered={
                                                                            item.hovered
                                                                        }
                                                                        isActive={
                                                                            item.isActive
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                            <span className="ml-4 font-display capitalize font-medium leading-7.5">
                                                                {t(
                                                                    `pages.${item.text}`
                                                                )}
                                                            </span>
                                                        </NavLink>
                                                    );
                                                })}
                                        </div>
                                    );
                                }
                            )}
                    </nav>
                    <button
                        className="flex gap-x-2  text-gray-300 items-center cursor-pointer"
                        onClick={() => setIsSupportModalOpen(true)}
                    >
                        <div className="font-bold font-display bg-gray-300  text-gray-700 rounded-full  px-2 py-1">
                            ?
                        </div>
                        <p className="font-medium font-display">Support</p>
                    </button>
                </div>
            </aside>
            <ModalWrapper
                modalState={isSupportModalOpen}
                setModalState={setIsSupportModalOpen}
                height={500}
                title="Send us a message"
            >
                <ModalSupportFormContent
                    userData={userState?.data ?? null}
                    setModalState={setIsSupportModalOpen}
                />
            </ModalWrapper>
        </>
    );
};

export default SidebarNavigation;
